import Vue from 'vue'
import VueRouter from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'helloWorld',
    component: HelloWorld
  },
  {
    path: '/not-found',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/NotFound.vue')
  },
  {
    path: '/contact/',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Contact.vue')
  },
  {
    path: '/profile/',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/Profile.vue')
  },

  //product 
  {
    path: '/product/',
    name: 'welding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Welding-page.vue')
  },
  {
    path: '/product/inverter',
    name: 'Inverter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Inverter-page.vue')
  },
  {
    path: '/product/multimedia',
    name: 'MultiMedia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Multimedia-page.vue')
  },
  {
    path: '/product/powerAmp',
    name: 'PowerAmp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/PowerAmp-page.vue')
  },
  {
    path: '/product/powerAmp/:id',
    name: 'PowerAmp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/PowerAmp-page.vue')
  },
  {
    path: '/product/Ups',
    name: 'Ups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Ups-page.vue')
  },
  {
    path: '/product/welding',
    name: 'welding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Welding-page.vue')
  },
  {
    path: '/product/welding/:id',
    name: 'welding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/product/Welding-page.vue')
  },
  //product 
  //service
  {
    path: '/service/automated-gate',
    name: 'AutomatedGate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/service/AutomatedGate-page.vue')
  },
  {
    path: '/service/expresso-machine',
    name: 'Expresso',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/service/ExpressoMachine-page.vue')
  },
  {
    path: '/service/solar-panel',
    name: 'SolarPanel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/service/SolarPanel-page.vue')
  },
  //service
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../components/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
