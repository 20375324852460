<template>
  <v-container class="container_hello">
    <v-row class="text-center">
      <v-carousel height="auto" cycle interval="6000" hide-delimiters>
        <v-carousel-item v-for="(item, i) in items" :key="i" eager>
          <v-img eager :src="item.img_name" contain />
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row style="padding: 24px">
      <v-col cols="12">
        <h1 color="secondary">Place holder - Slogan</h1>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam et
        dolores sint, vitae doloribus consequatur nisi quaerat ullam temporibus
        quae atque nihil blanditiis sed obcaecati, rerum laborum illum animi
        dolor.</v-col
      >

      <v-col class="respon-flex" cols="12">
        <v-col style="flex-grow: 1">
          <v-img
            :src="require('../assets/solar_header.png')"
            height="300"
            contain
          />
        </v-col>
        <v-col style="align-self: center; flex-grow: 3">
          <h2 color="secondary">Solar Panel</h2>
          <p>
            สามารถเลือกขนาดต่างๆ ให้เหมาะกับการในรูปแบบต่างๆ เช่น แผงโซล่าเซลล์
            5 วัตต์ นำไปใช้กับสัญญาณไฟกระพริบตามแยกต่างบนถนน โซล่าเซลล์ 10 วัตต์
            17 วัตต์ นำไปใช้กับไฟส่องสว่างตามทางเดิน โซล่าเซลล์ 225 วัตต์
            โซล่าเซลล์240วัตต์ โซล่าเซลล์ 245วั ตต์ โซล่าเซลล์280วัตต์
            นำไปใช้กับปั๊มน้ำพลังงานแสงอาทิตย์ เป็นต้น
          </p>
          <v-col class="respon-flex">
            <v-col
              class="respon-flex"
              lg="6"
              cols="12"
              style="padding-left: 0px"
            >
              <v-col style="flex-grow: 3; padding-left: 0px">
                <v-progress-linear
                  class="rounded-pill"
                  v-model="power_one"
                  color="secondary"
                  height="30"
                  style="margin-right: 20px"
                ></v-progress-linear>
                <p style="margin-top: 20px; margin-bottom: 5px">
                  ประหยัดค่าไฟฟ้า
                </p>
                <label style="font-size: 12px; color: #666666"
                  >ด้วยพลังงานหมุนเวียนจากแสงอาทิตย์</label
                >
              </v-col>
              <v-col style="margin-top: -10px">
                <label style="font-size: 30px">{{ power_one }}%</label>
              </v-col>
            </v-col>

            <v-col
              class="respon-flex"
              lg="6"
              cols="12"
              style="padding-left: 0px"
            >
              <v-col style="flex-grow: 3; padding-left: 0px">
                <v-progress-linear
                  class="rounded-pill"
                  v-model="power_two"
                  color="secondary"
                  height="30"
                  style="margin-right: 20px"
                ></v-progress-linear>
                <p style="margin-top: 20px; margin-bottom: 5px">
                  คุณภาพการผลิตไฟ
                </p>
                <label style="font-size: 12px; color: #666666"
                  >อินเวอร์เตอร์เทคโนโลยีทันสมัย</label
                >
              </v-col>
              <v-col style="margin-top: -10px">
                <label style="font-size: 30px">{{ power_two }}%</label>
              </v-col>
            </v-col>
          </v-col>
        </v-col>
      </v-col>

      <v-col class="respon-flex" cols="12">
        <v-col style="flex-grow: 1">
          <v-img
            eager
            :src="require('../assets/inverter_header.png')"
            height="300"
            contain
          />
        </v-col>
        <v-col
          class="order-md-first order-lg-first"
          style="align-self: center; flex-grow: 3"
        >
          <h2 color="secondary">Inverter</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam
            accusamus officiis repellat voluptates omnis facere eos tenetur
            magni quibusdam pariatur aliquam, saepe, blanditiis soluta cumque,
            dignissimos illo. Animi, veniam aut.
          </p>
        </v-col>
      </v-col>

      <v-col class="respon-flex" cols="12">
        <v-col style="flex-grow: 1">
          <v-img
            :src="require('../assets/inverter_header.png')"
            height="300"
            contain
          />
        </v-col>
        <v-col class="" style="align-self: center; flex-grow: 3">
          <h2 color="secondary">Inverter</h2>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Deserunt
            vitae accusantium voluptate possimus quo fugiat quibusdam architecto
            facilis at ipsam dolores harum quisquam animi nobis placeat
            laudantium amet, et sit.
          </p>
        </v-col>
      </v-col>

      <v-col cols="12" style="text-align: center">
        <h2 color="secondary">Products</h2></v-col
      >

      <v-col class="respon-flex" cols="12">
        <v-col lg="4" cols="12">
          <a :href="`/product/powerAmp/nbl-3000.json`">
            <v-img
              :src="require('../assets/nebula3000.jpg')"
              contain
              height="300"
            />
          </a>
          <br />
          <a :href="`/product/powerAmp/nbl-3000.json`">
            <h3 color="secondary" style="text-align: center">Nebula 3000</h3>
          </a>
        </v-col>

        <v-col lg="4" cols="12">
          <v-img :src="require('../assets/L7520.jpg')" contain height="300" />
          <br />
          <h3 color="secondary" style="text-align: center">L7-520</h3>
        </v-col>

        <v-col lg="4" cols="12">
          <v-img
            :src="require('../assets/phonon701.jpg')"
            contain
            height="300"
          />
          <br />
          <h3 color="secondary" style="text-align: center">Phonon 701</h3>
        </v-col>

        <!-- <v-col cols="4"></v-col>
         <v-col cols="4"></v-col> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.container_hello {
  max-width: 1200px !important;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.respon-flex {
  @media screen and (max-width: 1263px) {
    display: inline !important;
  }
  display: flex;
}

.v-responsive {
  flex: none !important;
}
</style>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    power_one: 40,
    power_two: 83,
    items: [
      { img_name: require("../assets/carousel_solarcell.png") },
      { img_name: require("../assets/carousel_coffee.png") },
      { img_name: require("../assets/carousel_manufacturing.png") },
    ],
  }),
  created() {
    document.title = "ORBIT., LTD. PART";
  },
};
</script>
