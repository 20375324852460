import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#F97B0B',
            secondary: '#974478',
            subsecondary: '#ff9638',
            footer: '#31373E',
            footer_font: '#888888'
          },
        },
      },
});
