<template>
  <v-app>
    <v-app-bar app color="primary" dark  v-on:mouseleave="mouseleave">
      <v-container class="nav-container">
        <a href="/" style="margin-right: auto">
          <v-img
            v-on:click="expand = false"
            :src="require('./assets/ORBIT_logo.svg')"
            contain
            height="75"
            style="cursor: pointer"
          />
        </a>
        <v-app-bar-nav-icon
          v-on:click="echo()"
          style="margin-left: auto"
          class="display-onMobile"
        ></v-app-bar-nav-icon>
        <!-- <v-spacer /> -->
        <v-toolbar-items class="display-PC">
          <v-btn
            v-on:mouseover="mouseover('product')"
            color="primary"
            style="
              box-shadow: none !important;
              padding: 0px 20px 0px 20px;
              font-size: 20px !important;
              font-weight: 300;
              cursor: pointer;
            "
            >PRODUCT</v-btn
          >
          <v-btn
            v-on:mouseover="mouseover('service')"
            color="primary"
            style="
              box-shadow: none !important;
              padding: 0px 20px 0px 20px;
              font-size: 20px !important;
              font-weight: 300;
              cursor: pointer;
            "
            >SERVICE</v-btn
          >
          <v-btn
            :to="'/' + item.page"
            v-on:mouseover="mouseover('nope')"
            color="primary"
            v-for="item in menuItems"
            :key="item.title"
            style="
              box-shadow: none !important;
              padding: 0px 20px 0px 20px;
              font-size: 20px !important;
              font-weight: 300;
              cursor: pointer;
            "
            >{{ item.title }}</v-btn
          >
        </v-toolbar-items>
        <v-expand-transition>
          <v-card
            v-if="nowon == 'product'"
            v-on:mouseover="mouseover('product')"
            d-flex
            v-show="expand"
            height="500"
            width="1200"
            class="expand"
          >
            <v-col class="col-4" style="margin: 24px 24px 24px 36px;">
              <hr style="margin-bottom: 12px" />
              <a href="/product/welding">
                <p
                  class="head-nav"
                  v-on:click="
                    nowPage = 'welding';
                    expand = false;
                  "
                  style="margin-bottom: 12px"
                >
                  WELDING MACHINE
                </p></a
              >
              <hr />
              <a href="/product/powerAmp">
                <p class="head-nav" style="margin: 12px 0px 12px 0px">
                  POWER AMPLIFIER
                </p></a
              >
              <hr />
              <a href="/product/ups">
                <p class="head-nav" style="margin: 12px 0px 12px 0px">UPS</p></a
              >
              <hr />
              <a href="/product/multimedia">
                <p class="head-nav" style="margin: 12px 0px 12px 0px">
                  MULTIMEDIA
                </p></a
              >
              <hr />
              <a href="/product/inverter">
                <p class="head-nav" style="margin: 12px 0px 12px 0px">
                  INVERTER
                </p></a
              >
              <hr />
            </v-col>
          </v-card>

          <v-card
            v-if="nowon == 'service'"
            v-on:mouseover="mouseover('service')"
            d-flex
            v-show="expand"
            height="500"
            width="1200"
            class="expand"
          >
            <v-col class="col-4" style="margin: 24px 24px 24px 36px;">
               <hr style="margin-bottom: 12px" />
              <a href="/service/solar-panel"
                ><p class="head-nav" style="margin-bottom: 12px">
                  SOLAR PANEL
                </p></a
              >
              <hr />
              <a href="/service/automated-gate">
                <p class="head-nav" style="margin: 12px 0px 12px 0px">
                  AUTOMATED GATE
                </p></a
              >
              <hr />
              <a href="/service/expresso-machine"
                ><p class="head-nav" style="margin: 12px 0px 12px 0px">
                  EXPRESSO MACHINE
                </p></a
              >
              <hr />
              <!-- loop -->
            </v-col>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card
            v-if="nowon == 'mobile'"
            d-flex
            v-show="expand"
            height="auto"
            width="100%"
            class="expand-mobile"
          >
            <v-col class="col-10" style="margin: 24px 24px 24px 24px;">
               <hr style="margin-bottom: 12px" />
              <!-- loop -->
              <a><p class="head-nav" style="margin-bottom: 12px; color: white" v-on:click="switchExpand('product')">
                  PRODUCT <i class="arrow down"></i>
                </p>
              </a>
              <v-col v-if="mobileExpand == 'product' && this.mobileIsExpand">
                <a href="/product/welding" style="color: white"
                  ><p style="margin-left: 16px">WELDING MACHINE</p>
                </a>
                <a href="/product/powerAmp" style="color: white"
                  ><p style="margin-left: 16px">POWER AMPLIFIER</p>
                </a>
                <a href="/product/ups" style="color: white"
                  ><p style="margin-left: 16px">UPS</p>
                </a>
                <a href="/product/multimedia" style="color: white"
                  ><p style="margin-left: 16px">MULTIMEDIA</p>
                </a>
                <a href="/product/inverter" style="color: white"
                  ><p style="margin-left: 16px">INVERTER</p>
                </a>
              </v-col>
              <hr />
              <a><p
                  class="head-nav"
                  style="margin: 12px 0px 12px 0px; color: white" v-on:click="switchExpand('service')">
                  SERVICE <i class="arrow down"></i>
                </p>
              </a>
              <v-col v-if="mobileExpand == 'service' && this.mobileIsExpand">
                <a href="/service/solar-panel" style="color: white"
                  ><p style="margin-left: 16px">SOLAR PANEL</p>
                </a>
                <a href="/service/automated-gate" style="color: white"
                  ><p style="margin-left: 16px; color: white">AUTOMATED GATE</p>
                </a>
                <a href="/service/expresso-machine" style="color: white"
                  ><p style="margin-left: 16px; color: white">
                    EXPRESSO MACHINE
                  </p>
                </a>
              </v-col>
              <hr />
              <a href="/profile">
                <p
                  class="head-nav"
                  style="margin: 12px 0px 12px 0px; color: white"
                >
                  PROFILE
                </p></a
              >
              <hr />
              <a href="/contact"
                ><p
                  class="head-nav"
                  style="margin: 12px 0px 12px 0px; color: white"
                >
                  CONTACT
                </p></a
              >
              <hr />
              <!-- loop -->
            </v-col>
          </v-card>
        </v-expand-transition>
      </v-container>
      <!-- <v-spacer />
      <v-spacer />
      <v-spacer />
      <v-spacer /> -->
    </v-app-bar>

    <v-main>
      <router-view />
      <!-- <HelloWorld v-if="nowPage == 'home'" />
      <Welding v-if="nowPage == 'welding'" /> -->
    </v-main>

    <v-footer color="footer">
      <v-container class="nav-container-foot">
        <v-col>
          <h2 style="color: white; margin-bottom: 16px">ORBIT., LTD. PART</h2>
          <p class="footer-font">เกี่ยวกับเรา</p>
          <p class="footer-font">ติดต่อเรา</p>
        </v-col>

        <v-col class="foot-img">
          <a href="https://www.facebook.com/orbit.co.th">
            <v-col cols="12">
              <img src="./assets/ORBIT_footer.svg" alt="" srcset="" />
            </v-col>
          </a>
          <v-col cols="12">
            <a href="https://www.facebook.com/orbit.co.th">
              <img
                src="./assets/ico_facebook.svg"
                alt=""
                srcset=""
                width="35"
                style="margin-right: 10px"
              />
            </a>
            <a href="https://line.me/R/ti/p/@268pncrn">
              <img src="./assets/ico_line.svg" alt="" srcset="" width="35" />
            </a>
          </v-col>
        </v-col>
      </v-container>
      <v-container class="nav-container-foot pa-0 text-right" style="">
        <v-col class="pa-0 ma-0">
          <p class="footer-font">
            © 2022 ORBIT., LTD. PART All Rights Reserved. Privacy Policy Terms &
            Conditions
          </p>
        </v-col>
      </v-container>
    </v-footer>
  </v-app>
</template>

<style lang="scss">
$body-font-family: "Kanit";
$background-color: #e5e5e5;
// Alegreya Sans SC-light
h1,
h2,
h3,
h4 {
  color: #974478;
  font-weight: 400;
}
a {
  text-decoration: none;
}

a:hover {
  text-decoration-color: #888888 !important;
  text-decoration: underline;
}
.head-nav {
  cursor: pointer;
  color: black;
  font-size: 20px;
  font-family: "Alegreya Sans SC", sans-serif !important;
}
.footer-font {
  color: #888888;
  margin-bottom: 6px !important;
}
.foot-img {
  flex-grow: 0;
  .v-image__image--contain {
    background-position: right center !important;
  }
}
.v-application {
  font-family: $body-font-family, sans-serif !important;
}
.v-main {
  padding-bottom: 24px !important;
  background-color: $background-color;
}
// .v-image__image--contain {
//   background-position: left center !important;
// }
.expand {
  margin-left: -12px !important;
  top: 64px;
  z-index: -10;
  // padding: 24px 24px 24px 24px;
  position: absolute !important;
  background-color: rgba(255, 255, 255) !important;
  position: fixed;
}
.nav-container {
  display: flex;
  background-color: #f97b0b;
  height: 40px;
  align-items: center;
  box-shadow: none;
  .v-responsive {
    flex: none !important;
  }
  max-width: 1200px !important;
}
.nav-container-foot {
  display: flex;
  background-color: #31373e;
  align-items: center;
  box-shadow: none;
  max-width: 1200px !important;
}
hr {
  border-color: black;
  border-top-width: 0px;
}
.display-onMobile {
  @media screen and (max-width: 1263px) {
    display: block !important;
  }
  @media screen and (min-width: 1264px) {
    display: none !important;
  }
}

.display-PC {
  @media screen and (max-width: 1263px) {
    display: none !important;
  }
  @media screen and (min-width: 1264px) {
    display: block !important;
  }
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 36px !important;
  font-size: 36px !important;
  width: 36px !important;
}

.expand-mobile {
  margin-left: -12px !important;
  top: 56px;
  z-index: -10;
  position: absolute !important;
  background-color: #ff9638 !important;
  position: fixed;
  left: 12px;
  border-radius: 0px !important;
  hr {
    border-color: #ffffff !important;
  }
  a, a:hover, a:active, a:visited, a:focus {
    text-decoration:none !important;
}
}

.arrow {
  margin-left: 20px;
  margin-bottom: 5px;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
<script>
export default {
  name: "App",

  data: () => ({
    mobileIsExpand: false,
    mobileExpand: "",
    nowPage: "home",
    nowon: "",
    expand: false,
    whatprod: "",
    menuItems: [
      { title: "PROFILE", path: "/home", page: "profile" },
      { title: "CONTACT", path: "/signin", page: "contact" },
    ],
  }),
  created() {
    document.title = "ORBIT., LTD. PART";
  },
  methods: {
    echo() {
      this.nowon = "mobile";
      this.expand = !this.expand;
    },
    switchExpand(expandData){
      this.mobileExpand = expandData;
      this.mobileIsExpand = !this.mobileIsExpand;
    },
    mouseover(product) {
      if (product == "product") {
        this.expand = true;
        this.nowon = "product";
      } else if (product == "service") {
        this.expand = true;
        this.nowon = "service";
      } else this.expand = false;
    },

    mouseleave() {
      this.expand = false;
    },
  },
};
</script>
